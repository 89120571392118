import './NotFound.styles.scss';

import React from 'react';
// import { Link, Typography } from '@material-ui/core';
// import { Link as RouterLink } from 'react-router-dom';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';

const NotFoundPage = () => {
  DocTitleScrollTop('Not Found');

  return (
    <div className="notFoundPage">
      <div class="number">404</div>
      <div class="text">
        <span>Ooops...</span>
        <br />
        page not found
      </div>
      {/* <a
        class="me"
        href="https://codepen.io/uzcho_/pens/popular/?grid_type=list"
        target="_blank"></a> */}
    </div>
  );
};

export default NotFoundPage;
