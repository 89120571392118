/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { getFromApi } from '../../apis/apis';
import Logo from '../../common/logo/Logo';
import './MainAppBarLg.scss';

const MainAppBarLg = ({ className }) => {
  const [time, setTime] = useState(0);
  const [fetchCount, setFetchCount] = useState(0);
  useEffect(() => {
    let isMounted = true;
    const getFeatures = async () => {
      try {
        const response = await getFromApi(`/timer-details`);
        if (response.response_code === 200) {
          setTime(parseFloat(response?.data?.minutes) * 60 * 1000);
          // setTime(parseFloat(response?.data?.minutes));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getFeatures();
    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const handleInterval = () => {
    setFetchCount((prev) => prev + 1);
    forceUpdate();
  };

  useEffect(() => {
    setFetchCount((prev) => prev + 1);
    if (time > 0) {
      setInterval(handleInterval, time);
    }
    return () => {
      clearInterval(handleInterval);
    };
  }, [time]);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      // forceUpdate();
    } else if (!completed) {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
    return '00:00';
  };
  return (
    <div className={className}>
      <div className="custom-container">
        <div className="nav-content-wrapper">
          <div className="countdown-wrapper">
            <Countdown key={fetchCount} date={Date.now() + time} renderer={renderer} />
          </div>
          {/* {time >= 0 ? (
            <div className="countdown-wrapper">
              <Countdown key={fetchCount} date={Date.now() + time} renderer={renderer} />
            </div>
          ) : null} */}
          <Logo className="main-app-bar-logo" />
        </div>
      </div>
    </div>
  );
};

export default MainAppBarLg;
