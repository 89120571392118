import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingHome: false,
  setLoadingHome: (v) => {},
  allFeatures: null,
  setAllFeatures: (v) => {},
  allInfo: [],
  setAllInfo: (v) => {},
  Newformats: [],
  setNewformats: (v) => {},
  BestSellers: [],
  setBestSellerSections: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  congratsModalOpened: false,
  setCongratsModalOpened: (v) => {},
  minutes: 0,
  setMinutes: (v) => {}
};

const HomeContext = createContext(INITIAL_VALUES);

export const HomeProvider = ({ children }) => {
  const [loadingHome, setLoadingHome] = useState(INITIAL_VALUES.loadingHome);
  const [allFeatures, setAllFeatures] = useState(INITIAL_VALUES.allFeatures);
  const [allInfo, setAllInfo] = useState(INITIAL_VALUES.allInfo);
  const [Newformats, setNewformats] = useState(INITIAL_VALUES.Newformats);
  const [BestSellers, setBestSellers] = useState(INITIAL_VALUES.BestSellers);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [congratsModalOpened, setCongratsModalOpened] = useState(
    INITIAL_VALUES.congratsModalOpened
  );
  const [minutes, setMinutes] = useState(INITIAL_VALUES?.minutes);
  return (
    <HomeContext.Provider
      value={{
        loadingHome,
        setLoadingHome,
        allFeatures,
        setAllFeatures,
        allInfo,
        setAllInfo,
        Newformats,
        setNewformats,
        BestSellers,
        setBestSellers,
        fetchCount,
        setFetchCount,
        congratsModalOpened,
        setCongratsModalOpened,
        minutes,
        setMinutes
      }}>
      {children}
    </HomeContext.Provider>
  );
};

export default HomeContext;
