import React, { useEffect } from 'react';
import { useState } from 'react';
import MainAppBarLg from './MainAppBarLg';
import MainAppBarMd from './MainAppBarMd';

const MianAppBar = () => {
  // detect route

  /////////////////////////////////////////

  return (
    <>
      <MainAppBarLg className="main-app-bar main-app-bar-lg" />

      <MainAppBarMd className="main-app-bar main-app-bar-md" />
    </>
  );
};

export default MianAppBar;
