/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext } from 'react';
import MomentUtils from '@date-io/moment';
import { create } from 'jss';
import { ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core';
import rtl from 'jss-rtl';
import theme from '../../theme';
import { useTranslation } from 'react-i18next';
import Loading from '../../common/loading/Loading';
// import 'antd/dist/antd.css';
import Routess from './Routess';
import '../../i18n';
import AppLayout from './Layout';
import { useEffect } from 'react';
import axios from 'axios';
// import { getServicesApi } from '../../apis/services';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import MianAppBar from '../main-app-bar/MianAppBar';
// import Fade from 'react-reveal/Fade';
import AOS from 'aos';
import { HomeProvider } from '../../contexts/home-context/HomeProvider';

// axios.defaults.baseURL = 'https://mazad.wgresa.com/api';
axios.defaults.baseURL = 'https://backendmazad.almaharh.com/api';

function App() {
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  useEffect(() => {
    AOS.init({
      duration: 1500
    });
  }, []);

  // useEffect(() => {
  //   setModalComponent(<LoginPage />);
  // }, [i18n.language], modalComponent, setModalComponent);

  // const handleOpen = () => setOpen(true);

  return (
    // <Fade>
    <>
      <div className={`app app-${i18n.dir()}`}>
        <ThemeProvider theme={theme(i18n.dir())}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <StylesProvider jss={jss}>
              <Suspense fallback={<Loading />}>
                {/* <Suspense> */}
                <AppLayout>
                  <HomeProvider>
                    {/* <button onClick={setModalOpen}>open</button> */}
                    <Routess />
                  </HomeProvider>
                </AppLayout>
              </Suspense>
            </StylesProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </div>
    </>
    // </Fade>
  );
}

export default App;
