// import { Drawer, IconButton } from '@material-ui/core';
import { Drawer, IconButton } from '@mui/material';
import React from 'react';
import { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { NavLink } from 'react-router-dom';
// import LanguageButton from '../../common/language-button/LanguageButton';
import Logo from '../../common/logo/Logo';
// import mainAppBarLinks from './mainAppBarLinks';
import menu from '../../assets/imgs/icons/menu.png';
import './MainAppBarMd.scss';
import LanguageButton from '../../common/language-button/LanguageButton';

const MainAppBarMd = ({ className }) => {
  // const { t } = useTranslation();
  const [drawerOpened, setDrawerOpened] = useState(false);
  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpened((prevState) => !prevState);
  };

  // const renderNavLinks = () => {
  //   return (
  //     <ul>
  //       {mainAppBarLinks(t).map(({ id, name, link }) => (
  //         <li key={id} onClick={toggleDrawer()}>
  //           <NavLink className="nav-link" activeClassName="active-link" to={link} exact>
  //             {name}
  //             <div className="active-img-wrap"></div>
  //           </NavLink>
  //         </li>
  //       ))}
  //     </ul>
  //   );
  // };

  return (
    <div className={className}>
      <div className="custom-container">
        <div className="nav-content-wrapper">
          <div className="nav-lang-wrapper">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer()}
              edge="start">
              <img src={menu} alt="menu" />
            </IconButton>
            <LanguageButton />
          </div>

          <Logo className="main-app-bar-logo" />
        </div>
      </div>
      <Drawer
        className="header-md-drawer"
        // anchor="right"
        open={drawerOpened}
        onClose={toggleDrawer()}>
        <div className="logo-links">
          <Logo className="main-app-bar-logo" />

          {/* {renderNavLinks()} */}
        </div>
      </Drawer>
    </div>
  );
};

export default MainAppBarMd;
