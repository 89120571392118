import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactNotification from 'react-notifications-component';

import MianAppBar from '../main-app-bar/MianAppBar';
const AppLayout = ({ children }) => {
  const { i18n } = useTranslation();

  return (
    <div className={`app-layout app-${i18n.dir()}`}>
      <ReactNotification className={i18n.dir()} />
      <MianAppBar />
      {children}
      {/* <MainAppFooter /> */}
    </div>
  );
};

export default AppLayout;
