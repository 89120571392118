/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { getFromApi } from '../../apis/apis';
import HomeContext from '../../contexts/home-context/HomeProvider';

import HomePage from '../../pages/home-page/HomePage';
// import { BrowserRouter as Switch, Route } from 'react-router-dom';

import NotFoundPage from '../../pages/not-found-page/NotFoundPage';

import routerLinks from './routerLinks';

const Routess = () => {
  const { setAllFeatures, setLoadingHome } = useContext(HomeContext);
  const { i18n } = useTranslation();

  const myRequest = async () => {
    try {
      setLoadingHome(true);
      const response = await getFromApi(`/estate-details`);
      if (response.response_code === 200 && response?.data) {
        setAllFeatures(response.data);
        setLoadingHome(false);
      } else {
        setLoadingHome(false);
        setAllFeatures(null);
      }
    } catch (error) {
      setLoadingHome(false);
    }
  };

  useEffect(() => {
    myRequest();
  }, [i18n.language]);

  const handleInterval = () => {
    myRequest();
  };
  useEffect(() => {
    setInterval(handleInterval, 4000);
    return () => {
      clearInterval(handleInterval);
    };
  }, []);

  return (
    <Routes>
      <Route exact path={routerLinks.homePage} element={<HomePage />}></Route>

      {/* not found page ShopPage */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Routess;
